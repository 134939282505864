import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  Button,
  Form,
  ModalContent,
  ModalDescription,
  ModalHeader,
} from 'semantic-ui-react';
import { API, showError } from '../helpers';

const ExportTokens = () => {
  const [query, setQuery] = useState({});
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);

  const handleQueryChange = (key, value) => {
    count !== -1 && setCount(-1);
    if (key === 'end' || key === 'start') {
      value = dayjs(value).unix();
    }

    setQuery({ ...query, [key]: value });
  };

  const handleSubmit = async (e) => {
    if (count < 0) {
      showError('请先查询数量');
      return;
    }
    if (count < 1) {
      showError('没有需要导出的数据');
      return;
    }

    const { start, end, keyword } = query;

    setLoading(true);
    const res = await API.get(`/api/token/export`, {
      params: {
        start,
        end,
        keyword,
      },
      responseType: 'blob',
    });

    console.log(res);

    const url = URL.createObjectURL(new Blob([res.data]));
    console.log('down', url);
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.download = 'demo.xlsx';
    aTag.style.display = 'none';
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);

    setLoading(false);
  };

  const handleSearchCount = async () => {
    const { start, end, keyword } = query;

    setLoading(true);
    const res = await API.get(`/api/token/search/count`, {
      params: {
        start,
        end,
        keyword,
      },
    });
    const { success, message, count } = res.data;
    if (success) {
      setCount(count);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  return (
    <>
      <ModalHeader>导出令牌</ModalHeader>
      <ModalContent image>
        <ModalDescription>
          <Form onSubmit={(e) => e.preventDefault()}>
            开始日期
            <Form.Input
              type='datetime-local'
              onChange={(e) => handleQueryChange('start', e.target.value)}
            />
            结束日期
            <Form.Input
              type='datetime-local'
              onChange={(e) => handleQueryChange('end', e.target.value)}
            />
            令牌名称
            <Form.Input
              type='text'
              onChange={(e) => handleQueryChange('keyword', e.target.value)}
            />
          </Form>
          {count > -1 && (
            <p style={{ color: 'blue', marginTop: 10 }}>
              已查询到 {count} 个令牌
            </p>
          )}
          <div style={{ display: 'flex', marginTop: 10 }}>
            <Button onClick={handleSearchCount} loading={loading}>
              查询数量
            </Button>
            <Button onClick={handleSubmit} loading={loading}>
              导出
            </Button>
          </div>
        </ModalDescription>
      </ModalContent>
    </>
  );
};

export default ExportTokens;
